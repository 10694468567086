import React, { useState, useEffect } from "react";
import { Carousel, Modal } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";





function HAKGallery() {
  const [modalShow, setModalShow] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [imagesToShow, setImagesToShow] = useState(8);
  const [showLess, setShowLess] = useState(false); 

  const handleCloseModal = () => {
    setModalShow(false);
  };

  const handleOpenModal = (index) => {
    setSelectedImageIndex(index);
    setModalShow(true);
  };

  const handleLoadMore = () => {
    setImagesToShow(imagesToShow + 8);
    if (imagesToShow + 8 >= lowResImages.length) {
      setShowLess(true); // Oculta el botón "Ver Más" cuando se muestran todas las imágenes
    }
  };

  const handleShowLess = () => {
    setImagesToShow(8);
    setShowLess(false);
  };
  

  const lowResImages = [
    { id: 59, src: "img/HAK-gallery/62.jpg", alt: "Hire Toy claw machine" },
    { id: 59, src: "img/HAK-gallery/61.jpg", alt: "Hire Toy claw machine" },
    { id: 59, src: "img/HAK-gallery/60.jpg", alt: "Hire Toy claw machine" },
    { id: 58, src: "img/HAK-gallery/59.jpg", alt: "Hire Toy claw machine" },
    { id: 57, src: "img/HAK-gallery/58.jpg", alt: "Hire Toy claw machine" },
    { id: 56, src: "img/HAK-gallery/57.jpg", alt: "Hire Toy claw machine" },
    { id: 55, src: "img/HAK-gallery/56.jpg", alt: "Hire Toy claw machine" },
    { id: 54, src: "img/HAK-gallery/55.jpg", alt: "Hire Toy claw machine" },
    { id: 53, src: "img/HAK-gallery/54.jpg", alt: "Hire Toy claw machine" },
    { id: 52, src: "img/HAK-gallery/53.jpg", alt: "Hire Toy claw machine" },
    { id: 51, src: "img/HAK-gallery/52.jpg", alt: "Hire Toy claw machine" },
    { id: 50, src: "img/HAK-gallery/51.jpg", alt: "Hire Toy claw machine" },
    { id: 49, src: "img/HAK-gallery/50.jpg", alt: "Hire Toy claw machine" },
    { id: 48, src: "img/HAK-gallery/49.jpg", alt: "Hire Toy claw machine" },
    { id: 47, src: "img/HAK-gallery/48.jpg", alt: "Hire Toy claw machine" },
    { id: 46, src: "img/HAK-gallery/47.jpg", alt: "Hire Toy claw machine" },
    { id: 45, src: "img/HAK-gallery/46.jpg", alt: "Hire Toy claw machine" },
    { id: 44, src: "img/HAK-gallery/45.jpg", alt: "Hire Toy claw machine" },
    { id: 43, src: "img/HAK-gallery/44.jpg", alt: "Hire Toy claw machine" },
    { id: 42, src: "img/HAK-gallery/43.jpg", alt: "Hire Toy claw machine" },
    { id: 41, src: "img/HAK-gallery/42.jpg", alt: "Hire Toy claw machine" },
    { id: 40, src: "img/HAK-gallery/41.jpg", alt: "Hire Toy claw machine" },
    { id: 39, src: "img/HAK-gallery/40.jpg", alt: "Hire Toy claw machine" },
    { id: 38, src: "img/HAK-gallery/39.jpg", alt: "Hire Toy claw machine" },
    { id: 37, src: "img/HAK-gallery/38.jpg", alt: "Hire Toy claw machine" },
    { id: 36, src: "img/HAK-gallery/37.jpg", alt: "Hire Toy claw machine" },
    { id: 35, src: "img/HAK-gallery/36.jpg", alt: "Hire Toy claw machine" },
    { id: 34, src: "img/HAK-gallery/31.jpg", alt: "Hire Toy claw machine" },
    { id: 33, src: "img/HAK-gallery/32.jpg", alt: "Hire Toy claw machine" },
    { id: 32, src: "img/HAK-gallery/33.jpg", alt: "Hire Toy claw machine" },
    { id: 31, src: "img/HAK-gallery/34.jpg", alt: "Hire Toy claw machine" },
    { id: 30, src: "img/HAK-gallery/35.jpg", alt: "Hire Toy claw machine" },
    { id: 1, src: "img/HAK-gallery/01.jpg", alt: "Hire Toy claw machine" },
    { id: 2, src: "img/HAK-gallery/02.jpg", alt: "Hire Toy claw machine" },
    { id: 3, src: "img/HAK-gallery/03.jpg", alt: "Hire Toy claw machine" },
    { id: 4, src: "img/HAK-gallery/04.jpg", alt: "Hire Toy claw machine" },
    { id: 5, src: "img/HAK-gallery/05.jpg", alt: "Hire Toy claw machine" },
    { id: 6, src: "img/HAK-gallery/06.jpg", alt: "Hire Toy claw machine" },
    { id: 7, src: "img/HAK-gallery/07.jpg", alt: "Hire Toy claw machine" },
    { id: 8, src: "img/HAK-gallery/08.jpg", alt: "Hire Toy claw machine" },
    { id: 9, src: "img/HAK-gallery/09.jpg", alt: "Hire Toy claw machine" },
    { id: 10, src: "img/HAK-gallery/10.jpg", alt: "skill tester" },
    { id: 11, src: "img/HAK-gallery/11.jpg", alt: "skill tester" },
    { id: 12, src: "img/HAK-gallery/12.jpg", alt: "skill tester" },
    { id: 13, src: "img/HAK-gallery/13.jpg", alt: "skill tester" },
    { id: 14, src: "img/HAK-gallery/14.jpg", alt: "skill tester" },
    { id: 15, src: "img/HAK-gallery/15.jpg", alt: "skill tester" },
    { id: 16, src: "img/HAK-gallery/16.jpg", alt: "skill tester" },
    { id: 17, src: "img/HAK-gallery/17.jpg", alt: "skill tester" },
    { id: 18, src: "img/HAK-gallery/18.jpg", alt: "Hire Toy crane machine" },
    { id: 19, src: "img/HAK-gallery/19.jpg", alt: "Hire Toy crane machine" },
    { id: 20, src: "img/HAK-gallery/20.jpg", alt: "Hire Toy crane machine" },
    { id: 21, src: "img/HAK-gallery/21.jpg", alt: "Hire Toy crane machine" },
    { id: 22, src: "img/HAK-gallery/22.jpg", alt: "Hire Toy crane machine" },
    { id: 23, src: "img/HAK-gallery/23.jpg", alt: "Hire Toy crane machine" },
    { id: 24, src: "img/HAK-gallery/24.jpg", alt: "Hire Toy crane machine" },
    // { id: 15, src: "img/HAK-gallery/25.jpg", alt: "" },
    { id: 26, src: "img/HAK-gallery/26.jpg", alt: "Hire Toy claw machine" },
    // { id: 17, src: "img/HAK-gallery/27.jpg", alt: "" },
    // { id: 18, src: "img/HAK-gallery/28.jpg", alt: "" },
    { id: 29, src: "img/HAK-gallery/29.jpg", alt: "Hire Toy claw machine" },
    // { id: 20, src: "img/HAK-gallery/30.jpg", alt: "" },

  ];


  return (
    <section className="gallery-section" id="Gallery">
      <div className='margin-title'>
      <div className='GAK-gallery-title'>PHOTO GALLERY</div>
      </div>
      <div className="GalleryHire">
        {lowResImages.slice(0, imagesToShow).map((image, index) => (
          <div className="responsive" key={image.id || index}>
            <div className="gallery">
              <a onClick={() => handleOpenModal(index)}>
                <img
                  src={image.src}
                  alt={image.alt}
                  width="800px"
                  height="600px"
                />
              </a>
            </div>
          </div>
        ))}
        <div className="button-position">
        {imagesToShow < lowResImages.length && !showLess && (
          <button className="SeeMoreGallery" onClick={handleLoadMore}>
            Show More
          </button>
        )}
        {showLess && (
          <button className="SeeMoreGallery" onClick={handleShowLess}>
            Show Less
          </button>
        )}
        </div>
    {/* </div> */}

<Modal show={modalShow} onHide={handleCloseModal}>
  <Modal.Header closeButton>
    <Modal.Title>{lowResImages[selectedImageIndex].alt}</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Carousel
      activeIndex={selectedImageIndex}
      onSelect={(index) => setSelectedImageIndex(index)} 
    >
      {lowResImages.map((lowResImages, index) => (
        <Carousel.Item key={lowResImages.id}>
          <img
            className="d-block w-100"
            src={lowResImages.src}
            alt={lowResImages.alt}
            style={{
              cursor: "pointer",
              maxHeight: "700px",
              objectFit: "contain",
            }}
          />
        </Carousel.Item>
      ))}
    </Carousel>
  </Modal.Body>
  <Modal.Footer>
    <button className="SeeMore" onClick={handleCloseModal}>Close</button>
  </Modal.Footer>
</Modal>


    </div>
    </section>
  );
}

export default HAKGallery;


